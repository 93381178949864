import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import "react-phone-input-2/lib/style.css"
import ErrorIcon from "../../assets/images/signup-error.svg"
import logoIcon from "../../assets/images/spsFooterLogoNew.svg"
import { productType, summaryPoints } from "../../constants/globalMessages"
import "../../styles/component/popup-signup.scss"
import SVGIcon from "./SVGIcon"

function PopupPlanTech(props) {
  const productData = ProductSetup().SuperOps.pages[0].newField[0]
  const noOfTechs = ["1", "2-3", "4-5", "6-10", "11-25", "26-50", "50+"]
  const [errorMsg1, setErrorMsg1] = useState(false)
  const [errorMsg2, setErrorMsg2] = useState(false)

  const canProceedToProduct = () => {
    if (props.planType && props.techCount) {
      props.callbackFn()
    } else {
      props.setDisableButton(false)
      if (!props.planType) setErrorMsg1(true)
      if (!props.techCount) {
        setErrorMsg2(true)
      }
      setTimeout(() => {
        setErrorMsg1(false)
        setErrorMsg2(false)
      }, 2000)
    }
  }
  const canSkipNow = () => {
    props.setPlanType("")
    props.setTechCount()
    props.setDisableButton(true)
    props.callbackFn("skipNowBtn")
  }
  useEffect(() => {
    const bodyTag = document.body
    if (bodyTag) bodyTag.classList.add("hide-scrollbar")
  }, [])

  return (
    <div className="popup-plantech">
      <div className="position-fixed product-setup" role="button" tabIndex={0}>
        <div className="position-fixed outer-wrap z-1"></div>
        <div className="inner-wrap w-100 h-100">
          <div className="icon-bar position-fixed d-flex flex-column h-100 justify-content-between">
            <div className="upper-icons d-flex align-items-center flex-column">
              <img
                src={logoIcon}
                alt="logo"
                className="sps-logo objcvr"
                width="10"
                height="10"
              />
              {productData.image.map(item => {
                return (
                  <div className="img-wrapr">
                    <img src={item.url} alt="icons" className="w-100" />
                  </div>
                )
              })}
            </div>

            <div className="lower-icons">
              <div className="name-letter text-uppercase mb20 d-flex justify-content-center align-items-center">
                {props.name[0]}
              </div>
            </div>
          </div>
          <div className="all-tabs d-flex w-100">
            <div className="w-100 home-tab align-self-end d-flex align-items-center justify-content-between">
              <div className="d-flex gap-8">
                <div className="img-wrapr d-flex">
                  <img
                    src={productData.image[0].url}
                    alt="icons"
                    className="w-100"
                  />
                </div>
                <p className="m-0">Home</p>
              </div>

              <img
                src="https://media.graphcms.com/zpyoXoSXRuWnSQ1KauLF"
                alt="close"
                height="24"
                width="24"
              />
            </div>
          </div>
          <div className="page-content h-100">
            <div className="welcome-box d-flex justify-content-between align-items-center mb10">
              <div className="d-flex align-items-center gap-24">
                <p className="m-0 name-letter d-flex justify-content-center align-items-center text-uppercase">
                  {props.name[0]}
                </p>
                <p className="m-0 good-day fw-600 p20">
                  Good day, {props.name}
                </p>
              </div>
              <p className="m-0 widget-btn d-dsk font-white p14">
                Widget Library
              </p>
            </div>
            <div className="d-flex flex-wrap pad-10">
              <Col lg={6} className="summary-points d-flex flex-wrap gap-8">
                {summaryPoints.map(item => {
                  return (
                    <Col
                      lg={4}
                      className="d-flex flex-column align-items-center justify-content-center point-box"
                    >
                      <h4 className="fw-bold">{Object.values(item)[0]}</h4>
                      <p className="m-0">{Object.keys(item)[0]}</p>
                    </Col>
                  )
                })}
              </Col>
              <Col lg={6} className="mentions">
                <p className="m-0 p20 fw-600 mb16">@Mentions</p>
                <div className="d-flex align-items-center gap-1">
                  <span className="name-letter font-white fw-bold d-flex align-items-center justify-content-center">
                    JW
                  </span>
                  <span className="fw-bold">John Wick</span>
                  <span>mentioned you in</span>
                  <span className="text-blue">Please provide me an iPhone</span>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </div>

      <Container className="position-fixed m-0 box-shad-none">
        <div className="slide-card down-to-up-1">
          <div className="overflow">
            {/* <p
              className={`${
                props.disableButton ? "dspnone" : ""
              } skip-btn back-skip cursor-pointer p12 fw-500 d-flex align-items-center m-0 position-fixed`}
              onClick={() => canSkipNow()}
            >
              skip now
              <SVGIcon name="navigation/Path" className="arrow" />
            </p> */}
            <p
              className={`${
                props.disableButton ? "dspnone" : ""
              } back-btn back-skip cursor-pointer p12 fw-500 d-flex align-items-center m-0 position-fixed`}
              onClick={() => props.setPopup(false)}
            >
              <SVGIcon name="navigation/Path" className="arrow" />
              back
            </p>
            <div className="d-flex justify-content-center">
              <div
                className={`api-error align-items-center m-0 ${
                  props.displayError ? "d-flex" : "dspnone"
                }`}
              >
                <img
                  src={ErrorIcon}
                  height="10"
                  width="10"
                  className="signup-error-icon"
                  alt="img"
                />
                <span className="p14 text-start">
                  <p>{props.errorText}</p>
                </span>
              </div>
            </div>

            <h2 className="heading font-roboto text-deep-purple fw-800 mb16 mt24 text-center">
              Welcome to SuperOps!
            </h2>
            <div className="inner-container mx-auto">
              <p className="description mx-auto text-center">
                Set up your account and help us to give you personalized
                support!
              </p>
              <div className="position-relative">
                <p className="font-roboto text-deep-purple fw-600 ques">
                  Which Best Describes You?
                </p>
                {errorMsg1 && (
                  <p className="error-msg position-absolute p13 fw-500">
                    please select one describes you.
                  </p>
                )}
              </div>

              <div className="d-flex plan-boxes gap-8 flex-wrap">
                {Object.values(productType).map((item, idx) => {
                  return (
                    <p
                      className={`box p12 text-deep-purple fw-500 cursor-pointer m-0 ${
                        props.planType === item.name ? "selected" : ""
                      }`}
                      onClick={() => props.setPlanType(item.name)}
                    >
                      {item.name}
                    </p>
                  )
                })}
              </div>
              <div className="position-relative">
                <p className="font-roboto text-deep-purple fw-600 mt24 ques">
                  Number of Technicians
                </p>
                {errorMsg2 && (
                  <p className="error-msg position-absolute p13 fw-500">
                    please select the technician count.
                  </p>
                )}
              </div>

              <div className="d-flex tech-boxes gap-8 flex-wrap">
                {noOfTechs.map((item, idx) => {
                  return (
                    <p
                      className={`box p12 text-deep-purple fw-500 cursor-pointer m-0 ${
                        props.techCount === item ? "selected" : ""
                      }`}
                      onClick={() => props.setTechCount(item)}
                    >
                      {item}
                    </p>
                  )
                })}
              </div>
              <button
                type="button"
                className="btn btn-primary mt32"
                onClick={() => {
                  props.setDisableButton(true)
                  canProceedToProduct()
                }}
                disabled={props.disableButton}
              >
                {props.isAPILoading ? (
                  <div className="loading-anim">
                    LOADING
                    <span className="ellipsis-anim">
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </span>
                  </div>
                ) : (
                  "GET STARTED FOR FREE"
                )}
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PopupPlanTech

export const ProductSetup = () => {
  const data = useStaticQuery(graphql`
    query productContent {
      SuperOps {
        pages(where: { title: "Public Beta" }) {
          newField {
            ... on SuperOps_Card {
              image {
                url
              }
            }
          }
        }
      }
    }
  `)
  return data
}
